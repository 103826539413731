import * as core from '@mt-webpages/core'
import * as runtypes from 'runtypes'
import * as sentry from 'src/lib/sentry'
import * as subscription from './data/subscription'
import * as hubspotSubmitFormScriptConfig from './data/hubspotSubmitFormScriptConfig'
import * as hubspotSubmitFormsScriptConfig from './data/hubspotSubmitFormsScriptConfig'
import * as setupScriptConfig from './data/setupScriptConfig'
import * as mtWebpagesWebEnv from './data/mtWebpagesWebEnv'
import * as mtWebpagesWebContext from './data/mtWebpagesWebContext'
import * as mtWebpagesWeb from './data/mtWebpagesWeb'
import * as mtWebpagesWebEvent from './data/mtWebpagesWebEvent'
import * as hubspotEventHandlerService from './services/hubspotEventHandler'
import * as mtGtmService from './services/mtGtm'
import * as scripts from './scripts'

export const main = () => {
  const env = mtWebpagesWebEnv.fromEnv()
  let logger = mtWebpagesWebEnv.toLogger(env)
  const sentrySdkConfig = mtWebpagesWebEnv.toSentrySdkConfig(env)
  const sentrySdkScopeConfig = mtWebpagesWebEnv.toSentrySdkScopeConfig(env)
  if (sentrySdkConfig.success) {
    logger = core.data.logger.merge([logger, sentry.createLogger(sentrySdkConfig.value)])
    sentry.configureSdk(sentrySdkConfig.value)
    if (sentrySdkScopeConfig.success) {
      sentry.configureScope(sentrySdkScopeConfig.value)
    } else {
      logger({
        message: new runtypes.ValidationError(sentrySdkScopeConfig),
        level: 'warning'
      })
    }
  } else {
    logger({
      message: `Unable to configure Sentry SDK`,
      level: 'warning'
    })
  }
  const mtGtm = mtGtmService.toInterface({
    window: globalThis.window,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'mtGtm'
      })
    )(logger)
  })
  const hubspotEventHandler = hubspotEventHandlerService.toInterface({
    mtGtm,
    window: globalThis.window,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'hubspotEventHandler'
      })
    )(logger)
  })
  const context: mtWebpagesWebContext.MtWebpagesWebContext = {
    ...mtWebpagesWebContext.defaultContext,
    logger,
    mtGtm,
    hubspotEventHandler,
    hubspotFormConfig: {
      ...mtWebpagesWebContext.defaultContext.hubspotFormConfig,
      mtWebpagesFormApiBaseUrl: env.mtWebpagesFormApiBaseUrl
    }
  }
  const MtWebpagesWeb: mtWebpagesWeb.MtWebpagesWeb = {
    scripts: {
      setup: subscription.onWindowLoad<setupScriptConfig.SetupScriptOptions>(options =>
        scripts.setup.main(options)(context)
      )(context.window),
      hubspotSubmitForm: {
        from: subscription.onWindowLoad<hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfigOptions>(options =>
          scripts.hubspotSubmitForm.from(options)(context)
        )(context.window)
      },
      hubspotSubmitForms: {
        from: subscription.onWindowLoad<hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfigOptions>(options =>
          scripts.hubspotSubmitForms.from(options)(context)
        )(context.window)
      }
    }
  }

  Object.assign(globalThis, { MtWebpagesWeb })
  context.window.dispatchEvent(mtWebpagesWebEvent.toDomEvent(mtWebpagesWebEvent.loadEvent)(context))
}

main()
