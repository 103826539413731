import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'
import * as hubspotFormFieldMetadata from './hubspotFormFieldMetadata'

export type HubspotFormEvent =
  | {
      type: 'fieldChange'
      metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata
    }
  | {
      type: 'formSubmitStart'
    }
  | {
      type: 'formSubmitFailure'
      errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
    }
  | {
      type: 'formSubmitSuccess'
    }
  | {
      type: 'noop'
    }

export const fieldChange = (metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata): HubspotFormEvent => ({
  type: 'fieldChange',
  metadata
})

export const formSubmitStart: HubspotFormEvent = {
  type: 'formSubmitStart'
}

export const formSubmitFailure = (
  errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
): HubspotFormEvent => ({
  type: 'formSubmitFailure',
  errorDetail
})

export const formSubmitSuccess: HubspotFormEvent = {
  type: 'formSubmitSuccess'
}

export const noop: HubspotFormEvent = {
  type: 'noop'
}
