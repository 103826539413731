import * as core from '@mt-webpages/core'
import * as hubspotFormFieldStatus from './hubspotFormFieldStatus'
import * as hubspotFormElement from 'src/data/hubspotFormElement'
import * as hubspotFormFieldMetadata from 'src/data/hubspotFormFieldMetadata'
import * as hubspotFormFieldElement from 'src/data/hubspotFormFieldElement'
import * as hubspotFormFieldContainerElement from 'src/data/hubspotFormFieldContainerElement'
import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'
import * as hubspotFormConfig from './hubspotFormConfig'
import * as mtWebflowFormElement from 'src/data/mtWebflowFormElement'
import * as mtWebpagesHubspotFormApiSubmitInput from './mtWebpagesHubspotFormApiSubmitInput'
import * as hubspotFormEvent from './hubspotFormEvent'

export type HubspotFormState = {
  fields: Record<
    string,
    {
      metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata
    }
  >
  submission:
    | {
        status: 'idle' | 'loading' | 'success'
      }
    | {
        status: 'failure'
        errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
      }
}

export const fromForm = (form: hubspotFormElement.HubspotFormElement): HubspotFormState => {
  return {
    submission: {
      status: 'idle'
    },
    fields: hubspotFormFieldElement
      .fromForm(form)
      .map(hubspotFormFieldElement.toHubspotFormFieldMetadata)
      .reduce((acc, metadata) => ({ ...acc, [metadata.name]: { metadata } }), {} as HubspotFormState['fields'])
  }
}

export const fromEvent = (event: hubspotFormEvent.HubspotFormEvent, state: HubspotFormState): HubspotFormState => {
  switch (event.type) {
    case 'noop': {
      return state
    }
    case 'fieldChange': {
      return {
        ...state,
        fields: {
          ...state.fields,
          [event.metadata.name]: {
            metadata: event.metadata
          }
        }
      }
    }
    case 'formSubmitStart': {
      return {
        ...state,
        submission: { status: 'loading' }
      }
    }
    case 'formSubmitSuccess': {
      return {
        ...state,
        submission: { status: 'success' }
      }
    }
    case 'formSubmitFailure': {
      return {
        ...state,
        submission: { status: 'failure', errorDetail: event.errorDetail }
      }
    }
    default: {
      return core.lib.function.absurd(event)
    }
  }
}

export const toMtWebpagesHubspotFormApiSubmitInput = (
  formState: HubspotFormState
): mtWebpagesHubspotFormApiSubmitInput.MtWebpagesHubspotFormApiSubmitInput => {
  return core.lib.record.filterMap(fieldState => {
    const value = toSubmittableValueByFieldMetadata(fieldState.metadata, formState)
    if (value !== null) {
      return {
        value,
        name: fieldState.metadata.name,
        objectTypeId: fieldState.metadata.objectTypeId
      }
    } else {
      return null
    }
  }, formState.fields)
}

export const toSubmittableValueByFieldMetadata = (
  metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata,
  formState: HubspotFormState
): string | null => {
  return toFieldStatusByFieldMetadata(metadata, formState) === 'active'
    ? hubspotFormFieldMetadata.toSubmittableValue(metadata)
    : null
}

export const toFieldStatusByFieldMetadata = (
  metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata,
  formState: HubspotFormState
): hubspotFormFieldStatus.HubspotFormFieldStatus => {
  if (metadata.disabled) {
    return 'disabled'
  } else {
    const triggerFieldState = formState.fields[metadata.triggerFieldName || '']
    if (triggerFieldState) {
      const triggerFieldValue = toSubmittableValueByFieldMetadata(triggerFieldState.metadata, {
        ...formState,
        fields: core.lib.record.omit([metadata.name, triggerFieldState.metadata.name], formState.fields)
      })
      return metadata.triggerFieldValue === triggerFieldValue ? 'active' : 'hidden'
    } else {
      return 'active'
    }
  }
}

export const toFieldStatuses = (
  formState: HubspotFormState
): Record<string, hubspotFormFieldStatus.HubspotFormFieldStatus> => {
  return core.lib.record.map(
    fieldState => toFieldStatusByFieldMetadata(fieldState.metadata, formState),
    formState.fields
  )
}

export const updateDom =
  (initialFormState: HubspotFormState, currentFormState: HubspotFormState) =>
  (config: hubspotFormConfig.HubspotFormConfig): void => {
    updateFormDom(currentFormState)(config)
    updateFieldsDom(initialFormState, currentFormState)(config)
  }

export const updateFormDom =
  (formState: HubspotFormState) =>
  (config: hubspotFormConfig.HubspotFormConfig): void => {
    switch (formState.submission.status) {
      case 'idle': {
        return
      }
      case 'loading': {
        hubspotFormElement.setSubmitLoadingIndicator(config.formElement)(config)
        return
      }
      case 'success': {
        hubspotFormElement.unsetSubmitLoadingIndicator(config.formElement)(config)
        mtWebflowFormElement.showSuccessElement(config.formElement)(config)
        return
      }
      case 'failure': {
        hubspotFormElement.unsetSubmitLoadingIndicator(config.formElement)(config)
        hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail.match(
          () => {
            mtWebflowFormElement.showFailureElement(config.formElement)(config)
          },
          errorDetail => {
            if (errorDetail.formErrors.length) {
              mtWebflowFormElement.showFailureElement(config.formElement)(config)
            } else {
              hubspotFormElement.setFormValidationErrorDetail(errorDetail, config.formElement)(config)
            }
          }
        )(formState.submission.errorDetail)
        return
      }
      default: {
        return core.lib.function.absurd(formState.submission)
      }
    }
  }

export const updateFieldsDom =
  (initialFormState: HubspotFormState, currentFormState: HubspotFormState) =>
  (config: hubspotFormConfig.HubspotFormConfig): void => {
    Object.entries(toFieldStatuses(currentFormState)).forEach(([key, status]) => {
      hubspotFormFieldContainerElement.fromFormByFieldName(config.formElement, key).forEach(container => {
        if (status === 'active') {
          hubspotFormFieldContainerElement.show(container)
        } else if (status === 'hidden') {
          hubspotFormFieldContainerElement.hide(container)
        }
      })
      hubspotFormFieldElement.fromFormByFieldName(config.formElement, key).forEach(field => {
        const initialFieldState = initialFormState.fields[key]
        if (status === 'active' && initialFieldState) {
          hubspotFormFieldElement.enableValidation(initialFieldState.metadata)(field)
        } else if (status === 'hidden' || status === 'disabled') {
          hubspotFormFieldElement.disableValidation(field)
        }
      })
    })
  }
