import * as logger from '@mt-webpages/core/data/logger'
import * as logEntry from '@mt-webpages/core/data/logEntry'
import * as cookies from 'src/lib/cookies'
import * as subscription from 'src/data/subscription'
import * as mtWebpagesWebContext from 'src/data/mtWebpagesWebContext'
import * as hubspotFormElement from 'src/data/hubspotFormElement'
import * as hubspotSubmitFormsScriptConfig from 'src/data/hubspotSubmitFormsScriptConfig'
import * as hubspotSubmitFormsError from 'src/data/hubspotSubmitFormsError'
import * as hubspotSubmitForm from './hubspotSubmitForm'

export const from =
  (options: hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfigOptions) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): subscription.Subscription => {
    try {
      const config: hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfig = {
        hutk: cookies.get(context.hubspotFormConfig.hutkCookieName)(context.window) || undefined,
        window: context.window,
        logger: context.logger,
        hubspotEventHandler: context.hubspotEventHandler,
        ...context.hubspotFormConfig,
        ...hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfigOptions.check(options)
      }
      return addFormEventListeners({
        ...config,
        logger: logger.pipe(logEntry.addContext(config))(context.logger)
      })
    } catch (error: unknown) {
      handleConfigurationFailure(error)(options)({
        ...context,
        logger: logger.pipe(logEntry.addContext(options))(context.logger)
      })
      return () => null
    }
  }

export const addFormEventListeners = (
  config: hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfig
): subscription.Subscription => {
  return subscription.merge(
    Array.from(config.window.document.querySelectorAll(config.formElementQuery))
      .map(element => hubspotFormElement.fromElement(element))
      .map(formElement =>
        hubspotSubmitForm.addFormEventListeners({
          formElement,
          ...config,
          ...hubspotFormElement.toHubstpotFormMetadata(formElement),
          logger: logger.pipe(logEntry.addContext(config))(config.logger)
        })
      )
  )
}

export const handleConfigurationFailure =
  (unknownError: unknown) =>
  (_: hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfigOptions) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): void => {
    const error = hubspotSubmitFormsError.fromUnknownError(unknownError)
    context.logger(hubspotSubmitFormsError.toLogEntry(error))
  }
