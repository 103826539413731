import * as runtypes from 'runtypes'
import * as core from '@mt-webpages/core'
import * as request from 'src/lib/request'
import * as locale from './locale'
import * as hubspotEventHandlerInterface from './hubspotEventHandlerInterface'
import * as mtWebpagesHubspotFormApiConfig from './mtWebpagesHubspotFormApiConfig'
import * as hubspotSubmitFormResult from './hubspotSubmitFormResult'
import * as hubspotFormSubmitFieldErrorTranslation from './hubspotFormSubmitFieldErrorTranslation'
import * as hubspotFormElement from 'src/data/hubspotFormElement'

export const HubspotSubmitFormScriptConfigOptions = runtypes.Record({
  locale: runtypes.Optional(locale.Locale),
  hutk: runtypes.Optional(runtypes.String),
  defaultObjectTypeId: runtypes.Optional(core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId),
  formId: runtypes.String,
  formElement: hubspotFormElement.HubspotFormElement,
  formSuccessElementQuery: runtypes.Optional(runtypes.String),
  formFailureElementQuery: runtypes.Optional(runtypes.String),
  fieldErrorTranslation: runtypes.Optional(
    hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
  ),
  formFieldErrorDatasetIdentifier: runtypes.Optional(runtypes.String),
  formInvalidFieldCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListContainerCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListItemCssClassname: runtypes.Optional(runtypes.String),
  formSubmitLoadingClassname: runtypes.Optional(runtypes.String),
  client: runtypes.Optional(runtypes.Function),
  callback: runtypes.Optional(runtypes.Function)
})

export type HubspotSubmitFormScriptConfigOptions = runtypes.Static<typeof HubspotSubmitFormScriptConfigOptions>

export type HubspotSubmitFormScriptConfig = {
  window: Window
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  locale: locale.Locale
  hutk?: string
  mtWebpagesFormApiBaseUrl: string
  defaultObjectTypeId: core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId
  formId: string
  formElement: hubspotFormElement.HubspotFormElement
  formSuccessElementQuery: string
  formFailureElementQuery: string
  fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
  formFieldErrorDatasetIdentifier: string
  formInvalidFieldCssClassname: string
  formfieldErrorListContainerCssClassname: string
  formfieldErrorListCssClassname: string
  formfieldErrorListItemCssClassname: string
  formSubmitLoadingClassname: string
  hubspotEventHandler: hubspotEventHandlerInterface.HubspotEventHandlerInterface
  client: request.Client
  callback: (result: hubspotSubmitFormResult.HubspotSubmitFormResult) => void
}

export const toMtWebpagesApiConfig = (
  config: HubspotSubmitFormScriptConfig
): mtWebpagesHubspotFormApiConfig.MtWebpagesHubspotFormApiConfig => ({
  client: config.client,
  baseUrl: config.mtWebpagesFormApiBaseUrl,
  formId: config.formId,
  hutk: config.hutk,
  defaultObjectTypeId: config.defaultObjectTypeId,
  pageName: config.window.name,
  pageUri: config.window.location.href
})
