import * as mtGtmEvent from 'src/data/mtGtmEvent'
import * as mtGtmConfig from 'src/data/mtGtmConfig'
import * as mtGtmInterface from 'src/data/mtGtmInterface'

export const toInterface = (config: mtGtmConfig.MtGtmConfig): mtGtmInterface.MtGtmInterface => {
  return {
    sendEvent: event => sendEvent(event)(config)
  }
}

export const sendEvent = (event: mtGtmEvent.MtGtmEvent) => (config: mtGtmConfig.MtGtmConfig) => {
  config.logger({
    message: 'sending event',
    level: 'info'
  })
  if (config.window.dataLayer) {
    config.window.dataLayer.push(event)
    config.logger({
      message: 'sent event',
      level: 'info'
    })
  } else {
    config.logger({
      message: 'dataLayer is not initialized',
      level: 'warning'
    })
  }
}
