export type Client = (...request: Parameters<typeof fetch>) => Promise<Response>

export class NetworkError extends Error {
  constructor() {
    super('NetworkError')
  }
}

export class HttpError extends Error {
  response: Response
  body: unknown

  constructor(response: Response, body: unknown) {
    super('HttpError')
    this.response = response
    this.body = body
  }
}

export const send: Client = (...request: Parameters<typeof fetch>): Promise<Response> => {
  return fetch(...request)
    .catch(() => {
      throw new NetworkError()
    })
    .then(response => {
      if (response.ok) {
        return response
      } else {
        return parseJsonBody(response).then(body => {
          throw new HttpError(response, body)
        })
      }
    })
}

export const parseJsonBody = (response: Response): Promise<unknown> => {
  return response.json().catch(() => null)
}
