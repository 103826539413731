import * as runtypes from 'runtypes'
import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'

export const HubspotSubmitFormResultSuccess = runtypes.Record({
  type: runtypes.Literal('success'),
  payload: runtypes.Record({
    form: runtypes.InstanceOf(HTMLFormElement),
    formId: runtypes.String
  })
})

export type HubspotSubmitFormResultSuccess = runtypes.Static<typeof HubspotSubmitFormResultSuccess>

export const HubspotSubmitFormResultFailure = runtypes.Record({
  type: runtypes.Literal('failure'),
  payload: runtypes.Record({
    form: runtypes.InstanceOf(HTMLFormElement),
    formId: runtypes.String,
    errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
  })
})

export type HubspotSubmitFormResultFailure = runtypes.Static<typeof HubspotSubmitFormResultFailure>

export const HubspotSubmitFormResult = runtypes.Union(HubspotSubmitFormResultSuccess, HubspotSubmitFormResultFailure)

export type HubspotSubmitFormResult = runtypes.Static<typeof HubspotSubmitFormResult>

export const success = (payload: HubspotSubmitFormResultSuccess['payload']): HubspotSubmitFormResultSuccess => {
  return { type: 'success', payload }
}

export const failure = (payload: HubspotSubmitFormResultFailure['payload']): HubspotSubmitFormResultFailure => {
  return { type: 'failure', payload }
}
