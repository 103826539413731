import * as runtypes from 'runtypes'

export const HubspotFormCallbackFormSubmittedEventData = runtypes.Record({
  type: runtypes.Literal('hsFormCallback'),
  id: runtypes.String,
  eventName: runtypes.Union(runtypes.Literal('onFormSubmitted'))
})

export type HubspotFormCallbackFormSubmittedEventData = runtypes.Static<
  typeof HubspotFormCallbackFormSubmittedEventData
>

export const HubspotFormCallbackEvent = runtypes.InstanceOf(MessageEvent).And(
  runtypes.Record({
    data: runtypes.Union(HubspotFormCallbackFormSubmittedEventData)
  })
)

export type HubspotFormCallbackEvent = runtypes.Static<typeof HubspotFormCallbackEvent>
