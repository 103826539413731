import * as core from '@mt-webpages/core'
import * as request from 'src/lib/request'
import * as mtWebpagesHubspotFormApiConfig from 'src/data/mtWebpagesHubspotFormApiConfig'
import * as mtWebpagesHubspotFormApiSubmitInput from 'src/data/mtWebpagesHubspotFormApiSubmitInput'
import * as mtWebpagesHubspotFormApiSubmitError from 'src/data/mtWebpagesHubspotFormApiSubmitError'
import * as mtWebpagesHubspotFormApiSubmitErrorDetail from 'src/data/mtWebpagesHubspotFormApiSubmitErrorDetail'
import * as hubspotFormSubmitInput from 'src/data/hubspotFormSubmitInput'

export const submit =
  (fields: mtWebpagesHubspotFormApiSubmitInput.MtWebpagesHubspotFormApiSubmitInput) =>
  async (config: mtWebpagesHubspotFormApiConfig.MtWebpagesHubspotFormApiConfig): Promise<void> => {
    const requestBody: hubspotFormSubmitInput.HubspotFormSubmitInput = {
      submittedAt: Date.now(),
      fields: core.lib.record.map(
        field => ({
          ...field,
          objectTypeId: field.objectTypeId || config.defaultObjectTypeId
        }),
        fields
      ),
      formId: config.formId,
      context: {
        hutk: config.hutk,
        pageUri: config.pageUri,
        pageName: config.pageName
      }
    }

    try {
      await config.client(`${config.baseUrl}/api/hubspot/form/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      })
    } catch (error: unknown) {
      if (error instanceof request.HttpError && error.body) {
        throw new mtWebpagesHubspotFormApiSubmitError.MtWebpagesHubspotFormApiSubmitError(
          mtWebpagesHubspotFormApiSubmitErrorDetail.fromUnknown(error.body)
        )
      } else {
        throw error
      }
    }
  }
