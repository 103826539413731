import * as core from '@mt-webpages/core'

export class HubspotSubmitFormsError extends Error {
  message: string

  constructor(message: string) {
    super(`HubspotSubmitFormsError: ${message}`)
    this.message = message
  }
}

export const fromUnknownError = (error: unknown) => {
  if (error instanceof Error) {
    return new HubspotSubmitFormsError(error.message)
  } else {
    return new HubspotSubmitFormsError('Unknown error')
  }
}

export const toLogEntry = (error: HubspotSubmitFormsError): core.data.logEntry.LogEntry<Error> => {
  return {
    message: error,
    level: 'error',
    context: {
      message: error.message
    }
  }
}
