import * as runtypes from 'runtypes'
import * as mtWebpagesWebContext from './mtWebpagesWebContext'

export const MtWebpagesWebLoadEvent = runtypes.Record({
  type: runtypes.Literal('mtWebpagesWebLoad')
})

export type MtWebpagesWebLoadEvent = runtypes.Static<typeof MtWebpagesWebLoadEvent>

export const MtWebpagesWebEvent = runtypes.Union(MtWebpagesWebLoadEvent)

export type MtWebpagesWebEvent = runtypes.Static<typeof MtWebpagesWebEvent>

export const loadEvent: MtWebpagesWebLoadEvent = {
  type: 'mtWebpagesWebLoad'
}

export const toDomEvent =
  (event: MtWebpagesWebEvent) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): CustomEvent<void> => {
    try {
      return new CustomEvent(event.type)
    } catch (_) {
      // for IE compatibility
      const domEvent = context.window.document.createEvent('CustomEvent')
      return domEvent
    }
  }
