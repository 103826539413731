import * as core from '@mt-webpages/core'
import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'
import * as hubspotSubmitFormResult from './hubspotSubmitFormResult'

export class HubspotSubmitFormError extends Error {
  formId: string
  errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail

  constructor(result: hubspotSubmitFormResult.HubspotSubmitFormResultFailure) {
    super(`HubspotSubmitFormError: ${result.payload.errorDetail.message}`)
    this.formId = result.payload.formId
    this.errorDetail = result.payload.errorDetail
  }
}

export const toLogEntry = (error: HubspotSubmitFormError): core.data.logEntry.LogEntry<Error> => {
  return {
    message: error,
    level: 'error',
    context: {
      message: error.message,
      formId: error.formId,
      errorDetail: error.errorDetail
    }
  }
}
