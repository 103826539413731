import * as runtypes from 'runtypes'
import * as hubspotSubmitFormScriptConfig from './hubspotSubmitFormScriptConfig'

export const HubspotSubmitFormsScriptConfigOptions =
  hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfigOptions.omit('formId', 'formElement').And(
    runtypes.Record({
      formElementQuery: runtypes.Optional(runtypes.String)
    })
  )

export type HubspotSubmitFormsScriptConfigOptions = runtypes.Static<typeof HubspotSubmitFormsScriptConfigOptions>

export type HubspotSubmitFormsScriptConfig = Omit<
  hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig,
  'formId' | 'formElement'
> & {
  formElementQuery: string
}
