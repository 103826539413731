import * as core from '@mt-webpages/core'
import * as request from 'src/lib/request'
import * as subscription from './subscription'
import * as hubspotSubmitFormResult from './hubspotSubmitFormResult'
import * as hubspotFormSubmitFieldErrorTranslation from './hubspotFormSubmitFieldErrorTranslation'
import * as hubspotEventHandlerInterface from './hubspotEventHandlerInterface'
import * as mtGtmInterface from 'src/data/mtGtmInterface'
import * as locale from './locale'

export type MtWebpagesWebContext = {
  window: Window & typeof globalThis
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  mtGtm: mtGtmInterface.MtGtmInterface
  hubspotEventHandler: hubspotEventHandlerInterface.HubspotEventHandlerInterface
  hubspotFormConfig: {
    locale: locale.Locale
    hutkCookieName: string
    mtWebpagesFormApiBaseUrl: string
    defaultObjectTypeId: core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId
    formElementQuery: string
    formSuccessElementQuery: string
    formFailureElementQuery: string
    fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
    formFieldErrorDatasetIdentifier: string
    formInvalidFieldCssClassname: string
    formfieldErrorListContainerCssClassname: string
    formfieldErrorListCssClassname: string
    formfieldErrorListItemCssClassname: string
    formSubmitLoadingClassname: string
    client: request.Client
    callback: (result: hubspotSubmitFormResult.HubspotSubmitFormResult) => void
  }
}

export const defaultContext: MtWebpagesWebContext = {
  logger: () => null,
  window: globalThis.window,
  mtGtm: {
    sendEvent: () => null
  },
  hubspotEventHandler: {
    subscribe: () => subscription.empty,
    handleFormSubmission: () => null
  },
  hubspotFormConfig: {
    locale: 'ja',
    hutkCookieName: 'hubspotutk',
    mtWebpagesFormApiBaseUrl: '',
    defaultObjectTypeId: '0-1',
    formElementQuery: 'form[data-hubspot-form-id]',
    formSuccessElementQuery: '.w-form-done',
    formFailureElementQuery: '.w-form-fail',
    fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.defaultTranslations,
    formFieldErrorDatasetIdentifier: 'hubspotfielderror',
    formInvalidFieldCssClassname: 'mt-webpages-form-invalid-field',
    formfieldErrorListContainerCssClassname: 'mt-webpages-form-field-error-list-container',
    formfieldErrorListCssClassname: 'mt-webpages-form-field-error-list',
    formfieldErrorListItemCssClassname: 'mt-webpages-form-field-error-list-item',
    formSubmitLoadingClassname: 'mt-webpages-form-submit-loading',
    client: request.send,
    callback: () => null
  }
}
