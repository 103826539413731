import * as subscription from 'src/data/subscription'
import * as mtGtmEvent from 'src/data/mtGtmEvent'
import * as hubspotFormCallbackEvent from 'src/data/hubspotFormCallbackEvent'
import * as hubspotEventHandlerInterface from 'src/data/hubspotEventHandlerInterface'
import * as hubspotEventHandlerConfig from 'src/data/hubspotEventHandlerConfig'

export const toInterface = (
  config: hubspotEventHandlerConfig.HubspotEventHandlerConfig
): hubspotEventHandlerInterface.HubspotEventHandlerInterface => {
  return {
    subscribe: () => subscribe(config),
    handleFormSubmission: data => handleFormSubmission(data)(config)
  }
}

export const subscribe = (config: hubspotEventHandlerConfig.HubspotEventHandlerConfig): subscription.Subscription => {
  const listener = (event: MessageEvent) => {
    config.logger({
      message: 'handling message event',
      level: 'info'
    })
    if (hubspotFormCallbackEvent.HubspotFormCallbackEvent.guard(event)) {
      config.logger({
        message: 'handling hubspot form callback event',
        level: 'info'
      })
      if (event.data.eventName === 'onFormSubmitted') {
        handleFormSubmission(event.data.id)(config)
      }
    }
  }
  config.window.addEventListener('message', listener)
  return () => config.window.removeEventListener('message', listener)
}

export const handleFormSubmission =
  (formId: string) => (config: hubspotEventHandlerConfig.HubspotEventHandlerConfig) => {
    config.logger({
      message: 'handling hubspot form submission',
      level: 'info'
    })
    config.mtGtm.sendEvent(
      mtGtmEvent.hubspotFormSubmissionEvent({
        formId
      })
    )
  }
