import * as rxjs from 'rxjs'
import * as logger from '@mt-webpages/core/data/logger'
import * as logEntry from '@mt-webpages/core/data/logEntry'
import * as cookies from 'src/lib/cookies'
import * as subscription from 'src/data/subscription'
import * as mtWebpagesHubspotFormApi from 'src/lib/mtWebpagesHubspotFormApi'
import * as mtWebpagesWebContext from 'src/data/mtWebpagesWebContext'
import * as hubspotFormEvent from 'src/data/hubspotFormEvent'
import * as hubspotSubmitFormError from 'src/data/hubspotSubmitFormError'
import * as hubspotSubmitFormErrorDetail from 'src/data/hubspotSubmitFormErrorDetail'
import * as hubspotFormState from 'src/data/hubspotFormState'
import * as hubspotFormFieldElement from 'src/data/hubspotFormFieldElement'
import * as hubspotSubmitFormScriptConfig from 'src/data/hubspotSubmitFormScriptConfig'
import * as hubspotSubmitFormResult from 'src/data/hubspotSubmitFormResult'

export const from =
  (options: hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfigOptions) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): subscription.Subscription => {
    try {
      const config: hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig = {
        hutk: cookies.get(context.hubspotFormConfig.hutkCookieName)(context.window) || undefined,
        window: context.window,
        logger: context.logger,
        hubspotEventHandler: context.hubspotEventHandler,
        ...context.hubspotFormConfig,
        ...hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfigOptions.check(options)
      }
      return addFormEventListeners({
        ...config,
        logger: logger.pipe(logEntry.addContext(config))(config.logger)
      })
    } catch (error: unknown) {
      handleConfigurationFailure(error)(options)({
        ...context,
        logger: logger.pipe(logEntry.addContext(options))(context.logger)
      })
      return () => null
    }
  }

export const addFormEventListeners = (
  config: hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig
): subscription.Subscription => {
  const { formElement: form } = config
  const initialFormState = hubspotFormState.fromForm(form)
  const formEventChannel = new rxjs.BehaviorSubject(hubspotFormEvent.noop)
  const formEvent$ = rxjs.merge(
    formEventChannel,
    ...hubspotFormFieldElement
      .fromForm(form)
      .map(field =>
        rxjs
          .fromEvent(field, 'change')
          .pipe(rxjs.map(() => hubspotFormEvent.fieldChange(hubspotFormFieldElement.toHubspotFormFieldMetadata(field))))
      )
  )
  const formState$ = formEvent$.pipe(
    rxjs.scan((formState, event) => hubspotFormState.fromEvent(event, formState), initialFormState)
  )
  return rxjs
    .fromEvent(form, 'submit')
    .pipe(
      rxjs.withLatestFrom(
        formState$.pipe(
          rxjs.tap(formState => {
            hubspotFormState.updateDom(initialFormState, formState)(config)
          })
        )
      ),
      rxjs.filter(([_, formState]) => formState.submission.status !== 'loading'),
      rxjs.mergeMap(([event, formState]) =>
        rxjs.from(
          (async () => {
            event.stopPropagation()
            event.preventDefault()
            const input = hubspotFormState.toMtWebpagesHubspotFormApiSubmitInput(formState)
            formEventChannel.next(hubspotFormEvent.formSubmitStart)
            await mtWebpagesHubspotFormApi.submit(input)(hubspotSubmitFormScriptConfig.toMtWebpagesApiConfig(config))
            formEventChannel.next(hubspotFormEvent.formSubmitSuccess)
            config.hubspotEventHandler.handleFormSubmission(config.formId)
            return hubspotSubmitFormResult.success({ form, formId: config.formId })
          })()
        )
      ),
      rxjs.catchError((unknownError: unknown) => {
        return rxjs.of(
          hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail.match(
            errorDetail => {
              const result = hubspotSubmitFormResult.failure({ errorDetail, form, formId: config.formId })
              const error = new hubspotSubmitFormError.HubspotSubmitFormError(result)
              formEventChannel.next(hubspotFormEvent.formSubmitFailure(errorDetail))
              config.logger(hubspotSubmitFormError.toLogEntry(error))
              return result
            },
            errorDetail => {
              const result = hubspotSubmitFormResult.failure({ errorDetail, form, formId: config.formId })
              formEventChannel.next(hubspotFormEvent.formSubmitFailure(errorDetail))
              if (errorDetail.formErrors.length) {
                const error = new hubspotSubmitFormError.HubspotSubmitFormError(result)
                config.logger(hubspotSubmitFormError.toLogEntry(error))
              }
              return result
            }
          )(hubspotSubmitFormErrorDetail.fromUnknownError(unknownError))
        )
      })
    )
    .subscribe(config.callback).unsubscribe
}

export const handleConfigurationFailure =
  (unknownError: unknown) =>
  (options: hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfigOptions) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): void => {
    const result = hubspotSubmitFormResult.failure({
      errorDetail: hubspotSubmitFormErrorDetail.fromUnknownError(unknownError),
      form: options.formElement,
      formId: options.formId
    })
    const error = new hubspotSubmitFormError.HubspotSubmitFormError(result)
    context.logger(hubspotSubmitFormError.toLogEntry(error))
    options.callback?.call(this, result)
  }
